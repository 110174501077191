import React from 'react'
// import "./home.css"

import img1 from "../assets/1.webp"

import img2 from "../assets/2.webp"

import img3 from "../assets/3.webp"


export default function Mobile() {
  return (
    <>
<body class="flex bg-gray-100 font-sans items-center" style={{minHeight: '100vh'}}>
    <div>

    <a href="https://imp88maxwin.com/register?ads=651BB5F5E67BE&adType=Ads" target='_blank'>
<img className='object-fill  w-full p-2' src={img1} alt="" /></a>

<a  href="https://imp88maxwin.com/register?ads=651BB5F5E67BE&adType=Ads" target='_blank'>
<img className='object-fill w-full p-2' src={img2} alt="" /></a>

<a  href="https://imp88maxwin.com/register?ads=651BB5F5E67BE&adType=Ads" target='_blank'>
<img className='object-fill w-full p-2' src={img3} alt="" /></a>
</div>

</body>
    </>
  )
}
