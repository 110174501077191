import React from 'react'

import ImgX from "../assets/about.jpeg"
import Produk1 from "../assets/produk1.jpg"
import Produk2 from "../assets/produk2.jpg"
import Produk3 from "../assets/produk3.jpg"

export default function Home() {
  return (
    <div>
<body class="bg-gray-100">
   
    <nav class="bg-blue-900 p-4">
        <div class="container mx-auto flex justify-between items-center">
            <a href="/" class="text-white text-2xl font-semibold">Relax-Coffee</a>
            <ul class="flex space-x-6 text-white">
                <li><a href="#about" class="hover:underline">About</a></li>
                <li><a href="#why-choose-us" class="hover:underline">Why Choose Us</a></li>
                <li><a href="#products" class="hover:underline">Products</a></li>
                <li><a href="#contact" class="hover:underline">Contact</a></li>
            </ul>
        </div>
    </nav>


    <header class="bg-blue-900 text-white py-16 px-4">
        <div class="container mx-auto text-center">
            <h1 class="text-4xl font-semibold mb-4">Welcome to Relax-Coffee</h1>
            <p class="text-lg">Your Destination for Quality Coffee</p>
        </div>
    </header>

    <section id="about" class="py-16">
        <div class="container mx-auto flex items-center">
            <div class="w-1/2">
                <img src={ImgX} alt="About Relax-Coffee" class="rounded-full shadow-lg"/>
            </div>
            <div class="w-1/2 px-8">
                <h2 class="text-3xl font-semibold mb-4">About Us</h2>
                <p class="text-gray-700">Relax-Coffee is a coffee shop dedicated to providing high-quality coffee and a welcoming atmosphere. We source our coffee beans from the best farms and roast them to perfection, ensuring a delightful coffee experience for our customers.</p>
            </div>
        </div>
    </section>

   
    <section id="why-choose-us" class="bg-gray-100 py-16">
        <div class="container mx-auto text-center">
            <h2 class="text-3xl font-semibold mb-8">Why Choose Us</h2>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      
                <div class="bg-white rounded-lg shadow-lg p-6">
                    <h3 class="text-xl font-semibold mb-2">Quality Coffee</h3>
                    <p class="text-gray-700">We source and roast the finest coffee beans to deliver exceptional flavor in every cup.</p>
                </div>
                
                <div class="bg-white rounded-lg shadow-lg p-6">
                    <h3 class="text-xl font-semibold mb-2">Cozy Atmosphere</h3>
                    <p class="text-gray-700">Our welcoming cafe provides a cozy environment for you to relax and enjoy your coffee.</p>
                </div>
         
                <div class="bg-white rounded-lg shadow-lg p-6">
                    <h3 class="text-xl font-semibold mb-2">Friendly Staff</h3>
                    <p class="text-gray-700">Our team is dedicated to making your coffee experience memorable with exceptional service.</p>
                </div>
            </div>
        </div>
    </section>


    <section id="products" class="py-16">
        <div class="container mx-auto text-center">
            <h2 class="text-3xl font-semibold mb-8">Our Products</h2>
          
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
           
                <div class="bg-white rounded-lg shadow-lg p-6">
                    <img src={Produk1} alt="Product 1" class="mb-4"/>
                    <h3 class="text-xl font-semibold mb-2">Espresso</h3>
                    <p class="text-gray-700">$3.99</p>
                </div>
           
                <div class="bg-white rounded-lg shadow-lg p-6">
                    <img src={Produk2} alt="Product 2" class="mb-4"/>
                    <h3 class="text-xl font-semibold mb-2">Latte</h3>
                    <p class="text-gray-700">$4.99</p>
                </div>
       
                <div class="bg-white rounded-lg shadow-lg p-6">
                    <img src={Produk3} alt="Product 3" class="mb-4"/>
                    <h3 class="text-xl font-semibold mb-2">Cappuccino</h3>
                    <p class="text-gray-700">$4.99</p>
                </div>
            </div>
        </div>
    </section>

    
    <section id="contact" class="bg-blue-900 text-white py-16">
        <div class="container mx-auto text-center">
            <h2 class="text-3xl font-semibold mb-8">Contact Us</h2>
            <p class="text-lg">Have questions or want to visit us? Get in touch with our team.</p>
            <a href="mailto:contact@relaxcoffee.com" class="bg-yellow-500 text-blue-900 hover:bg-yellow-400 py-2 px-6 mt-8 rounded-full inline-block transition duration-300">Email Us</a>
        </div>
    </section>

    <footer class="bg-gray-900 text-white py-8">
        <div class="container mx-auto text-center">
            <p>&copy; 2023 Relax-Coffee. All rights reserved.</p>
        </div>
    </footer>
</body>
    </div>
  )
}
